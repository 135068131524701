.mobile>.row>.col {
    padding: 0 10%;
}

.mobile>.row>.col.home-page-mobile {
    padding: 5% 10%;
}

.mobile-img-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 10%;
}

.mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

#profile-pic-desktop {
    margin-top: 6.5vh;
    width: 45%;
    height: auto;
    border-radius: 15vh;
}

#profile-pic-mobile {
    width: 80%;
    height: auto;
}

.home-page-desktop {
    height: 100%;
    width: 50%;
    justify-content: center;
    align-content: center;
}

span.lazy-load-image-background.blur.lazy-load-image-loaded {
    display: flex !important;
    justify-content: center;
    width: 150%;
    height: 150%;
    margin-top: -10vh;
}