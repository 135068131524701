.App {
    height: 100vh;
    /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    --accentColor: #ff206e;
    font-family: 'Poppins', sans-serif;
}

.light .App {
    background: white;
    color: black;
}

.dark .App {
    background: #0c0f0a;
    color: white;
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.side-margin {
    margin: 0 3%;
}

li>p {
    margin-right: 3%;
}

.container {
    max-width: 750px;
}

.display-none {
    display: none;
}

.display-flex {
    display: flex;
}

.flex-direction-column {
    flex-direction: column;
}

.fp-slidesNav.fp-bottom {
    display: flex;
    justify-content: center;
    align-content: center;
    bottom: 10% !important;
}

.dark .fp-slidesNav.fp-bottom>ul>li>a>span {
    background: white
}

.light .fp-slidesNav.fp-bottom>ul>li>a>span {
    background: black
}

.experience-heading {
    position: absolute;
    width: 100%;
    top: 15%;
}

.index {
    margin-right: 10px
}

.project-link-container a {
    margin-right: 15px
}

.project-link-container a>i {
    transform: scale(1.25)
}

.accent-style, a {
    color: var(--accentColor);
}

.content-hover-link:hover {
    color: inherit !important;
}

.underline-style {
    text-decoration: var(--accentColor) underline
}

.cursor-pointer {
    cursor: pointer;
}

p {
    text-align: justify;
}

.align-self-center {
    align-items: center;
}

.blink_me {
    animation: blinker 1s linear 5;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.fp-slidesContainer {
    display: flex;
    align-items: center;
}

.fp-slides {
    height: auto !important;
}

.slide-move-disabled {
    opacity: 0.2;
    cursor: no-drop;
}